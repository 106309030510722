.ant-modal-wrap {
  backdrop-filter: blur(4px);
  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
  }
  &.max {
    .ant-modal-content {
      background: transparent;
    }
    .ant-modal {
      width: 90vw;
      max-width: 1500px;
      top: 50px;
      height: 90vh;
      .ant-modal-content {
        box-shadow: none;
        min-height: 90vh;
      }
    }
  }
  &.full {
    .ant-modal-content {
      background: transparent;
      border-radius: 10px 10px 0 0;
    }
    .ant-modal {
      width: 100vw;
      top: 50px;
      height: calc(100vh - 50px);
      max-width: 100vw;
      margin: 0 auto;
      .ant-modal-content {
        box-shadow: none;
        height: calc(100vh - 50px);
        .sk-modal-body {
          height: calc(100vh - 50px);
        }
      }
    }
  }
}
.ant-modal-body {
  .skSection:first-child {
    margin-top: 15px !important;
  }
}
