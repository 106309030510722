@import 'var';

.ant-form {
  &.ant-form-vertical {
    .ant-form-item {
      padding-top: 20px;
      margin-bottom: 0;
    }
    &.no-padding-for-form-item {
      .ant-form-item {
        padding-top: 0;
      }
    }
  }
}

.ant-form-item-label {
  font-weight: 500;
  > label {
    font-size: 12px;
  }
}

.ant-form-item-required {
  &:before {
    display: none !important;
  }

  &:after {
    display: inline-block !important;
    color: #f2545b !important;
    font-size: 12px !important;
    content: '*' !important;
  }
}

.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper-focused {
  border-color: transparent;
  box-shadow: 0 0 0 2px rgba(42, 94, 228, 0.4);
}

.ant-picker,
.ant-input-number,
.ant-input-number-handler-wrap,
.ant-input-affix-wrapper,
.ant-select-selector,
.ant-input {
  border-color: transparent !important;
  box-shadow: 0 0 0 2px rgb(226, 232, 240);
  &:focus {
    background-color: #fff !important;
  }
}

.ant-input-affix-wrapper {
  .ant-input {
    box-shadow: none;
  }
}

.ant-input-number {
  width: 100% !important;
}
.ant-select-selector {
  .ant-input {
    box-shadow: none !important;
  }
}
.ant-form-item-has-error {
  .ant-picker,
  .ant-input-number,
  .ant-input-affix-wrapper,
  .ant-select-selector,
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector,
  .ant-input {
    border-color: transparent !important;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.4);
    &:focus {
      background-color: #fff !important;
    }
  }

  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper-focused {
    border-color: transparent;
    box-shadow: 0 0 0 2px rgba(42, 94, 228, 0.4);
  }
}

.ant-form-item {
  &.hide-explain {
    .ant-form-item-explain {
      display: none;
    }
  }
}
