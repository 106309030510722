.ant-dropdown-menu {
  padding: 5px !important;
}

.ant-dropdown-menu-item {
  @apply rounded-lg;
  align-items: center;
  display: flex !important;

  > svg {
    margin-right: 10px;
    width: 20px;
  }

  &:hover {
    @apply text-blue2-600;
  }
}
