@import 'var';

.w-200 {
  width: 200px;
}

.min-w-100 {
  min-width: 100px;
}

.w-screen-min-side {
  width: calc(100vw - 16rem);
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.text-color {
  color: $text-color;
}

.text-muted {
  color: #516f90;
}

.min-h-screen {
  min-height: calc(100vh - 56px);
}

@media (min-width: 1480px) {
  .container {
    max-width: 1460px !important;
  }
}

a.underline {
  &:hover {
    text-decoration: underline;
  }
}

.scroll-design {
  &::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  &::-webkit-scrollbar-track {
    @apply bg-gray-100;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-gray-600;
    border-radius: 20px;
    border: 3px solid #f3f4f6;
  }
}

.scrollbar-gray {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-gray-500;
    border-radius: 0;
  }

  &::-webkit-scrollbar-track {
    @apply bg-gray-300;
  }
}

.image-contents {
  .ant-image {
    display: contents !important;
  }
}

.min-w-20 {
  min-width: 5rem !important;
}

.top-52 {
  top: 52px !important;
}

.top-54 {
  top: 54px !important;
}

.bg-primary-gradient {
  background-image: linear-gradient(-303deg, #2d52c4, #3979ed 56%, #3b9df1) !important;
}

.bg-dark {
  @apply bg-dark-600;
}
