.ant-select {
  &.h-10 {
    .ant-select-selector {
      height: 42px !important;
      .ant-select-selection-placeholder,
      .ant-select-selection-search-input {
        line-height: 40px !important;
        height: 40px !important;
      }
    }
  }
}
