//@import 'reset';
@import 'override';
@import 'form';
@import 'modal';
@import 'utilities';
@import 'btn';
@import 'dropdown-menu';
@import 'drawer';
@import 'table';
@import 'table-flex';
@import 'float-label';
@import 'select';
@import 'tabs';
@import 'picker-range';
@import 'notfication';
@import 'cookie-banner';
@import 'reset';

// hide recaptcha
.grecaptcha-badge { visibility: hidden; }
