#tarteaucitronAlertBig {
  background-color: #fff !important;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15) !important;
  .tarteaucitronCTAButton {
    background: rgb(0, 158, 224) !important;
    color: #fff !important;
  }
  #tarteaucitronDisclaimerAlert {
    color: #212121 !important;
    font-size: 16px !important;
    margin: 0 !important;
    order: 1;
  }
  #tarteaucitronPersonalize2 {
    order: 3;
  }
  #tarteaucitronAllDenied2 {
    order: 4;
  }
  #tarteaucitronPrivacyUrl {
    color: #212121 !important;
    text-decoration: underline;
    order: 2;
  }
  .tarteaucitronCTAButton {
    font-weight: bold;
    margin: 0.25rem auto !important;
    width: 100% !important;
    max-width: 180px !important;
  }
  #tarteaucitronCloseAlert {
    font-weight: bold;
    color: #212121 !important;
    background-color: #e5f5fc !important;
    display: block !important;
    width: 100% !important;
    margin: 0 !important;
    margin-top: 1rem !important;
    padding: 15px 0 !important;
    order: 5;
  }
  &.tarteaucitronAlertBigBottom {
    #tarteaucitronPrivacyUrl,
    .tarteaucitronCTAButton,
    #tarteaucitronCloseAlert {
      margin: 0.25rem !important;
      width: auto !important;
      padding: 10px 15px !important;
    }
  }
}

#tarteaucitronRoot {
  .tarteaucitronCheck,
  .tarteaucitronCross {
    display: none;
  }
  &:before {
    content: none !important;
  }
  button {
    border-radius: 2px;
    padding: 15px 40px !important;
  }
  #tarteaucitronClosePanel {
    padding: 4px 0 !important;
    border-radius: 4px 4px 0 0 !important;
  }
}

.tarteaucitronAlertBigTop {
  flex-direction: column !important;
  padding: 20px !important;
  left: 65% !important;
  width: 35% !important;
  border-radius: 2px !important;
}
.tarteaucitronAlertBigBottom {
  padding: 10px 0 !important;
}

div#tarteaucitronAlertBig:before {
  color: #212121 !important;
  font-size: 25px !important;
  display: block !important;
  margin-bottom: 1rem !important;
}

iframe {
  max-width: 100%;
  max-height: 100%;
}
