@import 'var';

.ant-table {
  .ant-form-item {
    padding-top: 0 !important;
  }
}
.ant-table-column-sorters {
  @apply justify-start;
  .ant-table-column-sorter {
    @apply pl-2;
  }
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.ant-table-column-title {
  display: contents;
}

.ant-table-thead > tr > th {
  font-size: 12px !important;
  text-transform: uppercase !important;
  color: $text-color !important;
  font-weight: 600 !important;
}
.table-edit {
  border-spacing: 10px;
  text-align: left;

  thead > tr > th {
    font-size: 12px;
    text-transform: uppercase;
    color: $text-color !important;
    border-bottom: 0 !important;
    font-weight: 600 !important;
    padding: 10px 10px 10px 5px !important;
  }

  tbody {
    tr {
      border-top: 1px solid #e2e8f0;

      td {
        padding: 10px 10px 10px 5px;
      }
    }
  }
}
.ant-table-wrapper {
  &.bg-transparent {
    .ant-table,
    .ant-table-thead > tr > th {
      background: transparent;
    }
  }
}
